import { Col, Container, Nav, NavItem, NavLink, Navbar, Row } from 'react-bootstrap';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import CONFIG from '../../config';

// import { NavLink } from 'react-router-dom';
const MobileHeader = () => {
  const location = useLocation();
  const imageUrl = `${process.env.PUBLIC_URL}/images/mobile/header_background.png`;
  const logoImg = `${process.env.PUBLIC_URL}/images/logo.svg`;
  const menuIcon = `${process.env.PUBLIC_URL}/images/mobile/Icon.svg`;
  const mobileImage = `${process.env.PUBLIC_URL}/images/mobile/mobile-home.png`;
  const googlePlayImage = `${process.env.PUBLIC_URL}/images/google-play.png`;
  const playStoreImage = `${process.env.PUBLIC_URL}/images/play-store.png`;
  const [isOpen, setIsOpen] = useState(false);
  const [isHomePage, setIsHomePage] = useState(location.pathname === '/');
  const [isFeaturePage, setIsFeaturePage] = useState(location.pathname.includes('/features'));
  const [isAboutPage,  setIsAboutPage] = useState(location.pathname.includes('/about-us'));
  const [isHelpCenterPage,  setIsHelpCenterPage] = useState(location.pathname.includes('/help-center'));
  const [position, setPosition] = useState(7.209); // Initial top margin

  const logoImage = `${CONFIG['BASE_IMAGE_URL']}/logo-header.png`;
  const apiImage = `${CONFIG['BASE_IMAGE_URL']}/api-header.png`;

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };
  const scrollToSection = () => {
    const section = document.getElementById('contactUs');
    if (section) {
      section.scrollIntoView({ behavior: 'smooth' });
    }
  };
  useEffect(() => {
    function handleScroll() {
      if (window.scrollY > 0) {
        setPosition(0); 
      } else {
        setPosition(7.209);
      }
    }
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []); 

  useEffect(() => {
    const handleRouteChange = () => {
      setIsHomePage(location.pathname === '/');
      setIsFeaturePage(location.pathname.includes('/features'));
      setIsAboutPage(location.pathname.includes('/about-us'));
      setIsHelpCenterPage(location.pathname.includes('/help-center'));
      
    };

    window.addEventListener('popstate', handleRouteChange);

    return () => {
      window.removeEventListener('popstate', handleRouteChange);
    };
  }, [location]);

  return (
    <header className= { "header"}>
      <Nav className="justify-content-end nav">
        <NavItem className='business'>
          <NavLink  href="https://web.mafhoom.app/"><span> Personal Finance </span></NavLink>
        </NavItem>
        <NavItem >
          <NavLink className="active" to="#"><span> Business </span></NavLink>
        </NavItem>
      </Nav>  
      <Row className="section-2 " >
                <Col md={6}>
                    <img src={logoImage} alt="logo"></img>
                    <p className="baloo-font-normal font-500 font-white">Empowering Finance: Seamless API Integration & Expert Solutions for B2B Banking</p>
                    <button className='montserrat-font-normal font-white fw-semibold' onClick={scrollToSection}>Talk to Us</button>
                </Col>
                <Col md={6} className='api-image'>
                    <img src={apiImage} alt="apiImage"></img>
                </Col>
            </Row>
    </header>
  );
};

export default MobileHeader;
