// App.js

import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Switch, Routes } from 'react-router-dom';
import './App.css';
import HomePage from './components/pages/home';
import Header from './components/layouts/header';
import MobileHeader from './components/layouts/mobile-header';
import Footer from './components/layouts/footer';
import 'bootstrap/dist/css/bootstrap.min.css';
import MobileFooter from './components/layouts/mobile-footer';
const App = () => {

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768); // Adjust breakpoint as needed
  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 768);
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);
  return (
    <Router>
      <div className="app">
      {isMobile ? <MobileHeader /> : <Header />}
        <Routes>
          <Route path="/" element={<HomePage />} />
        </Routes>
        {isMobile ? <MobileFooter /> : <Footer />}
      </div>
    </Router>
  );
};

export default App;
