// Header.js

import React from 'react';
import { Col, Nav, NavItem, Row } from 'react-bootstrap';
import CONFIG from '../../config';
import { NavLink } from 'react-router-dom';


const Header = () => {
    const logoImage = `${CONFIG['BASE_IMAGE_URL']}/logo-header.png`;
    const apiImage = `${CONFIG['BASE_IMAGE_URL']}/api-header.png`;



  const scrollToSection = () => {
    const section = document.getElementById('contactUs');
    if (section) {
      section.scrollIntoView({ behavior: 'smooth' });
    }
  };

    return (
        <header className="header">
            <Nav className="justify-content-end nav">
                <NavItem className="montserrat-font-normal nav-item-heading">
                    <NavLink className="montserrat-font-normal nav-item-heading" to="https://mafhoom.app/"><span> Personal Finance </span></NavLink>
                </NavItem>
                <NavItem className="nav-item-business montserrat-font-normal nav-item-heading">
                    <NavLink className="business activ-titel"  to="#"><span> Business </span></NavLink>
                </NavItem>
            </Nav>
            <Row className="section-2 " >
                <Col md={6}>
                    <img src={logoImage} alt="logo"></img>
                    <p className="baloo-font-normal font-500 font-white">Empowering Finance: Seamless API Integration & Expert Solutions for B2B Banking</p>
                    <button className='montserrat-font-normal font-white fw-semibold' onClick={scrollToSection}>Talk to Us</button>
                </Col>
                <Col md={6} className='api-image'>
                    <img src={apiImage} alt="apiImage"></img>
                </Col>
            </Row>
        </header>
    );
};

export default Header;
