

let CONFIG = [];
let BASE_IMAGE_URL;
var isMobile = false;



// const updateBaseUrl = () => {
    // if (window.innerWidth <= 768) {
    //     isMobile = true;
    //     BASE_IMAGE_URL = `${process.env.PUBLIC_URL}/images/mobile`;
    // } else {
        BASE_IMAGE_URL = `${process.env.PUBLIC_URL}/images`;
    // }
// };
// updateBaseUrl(); // Set the initial base image URL

const PRODUCTS = [
    {
        "key" : 'link_bank_account',
        "title": 'Link Bank Account',
        "heading": 'Unlock Business Potential with Mafhoom\'s Financial Tools',
        "image": `${BASE_IMAGE_URL}/link-bank-account.png`,
        "paragraph": 'We elevate businesses with cutting-edge financial tools, enabling consent-based financial data sharing. Through open banking, we connect consumers to services they need, fostering tailored solutions and enhanced engagement.',
    },
    {
        "key" : 'diagnose',
        "title": 'Diagnose',
        "heading": 'Enhance Client Insights with Mafhoom\'s Health and Wellness Tool',
        "image": `${BASE_IMAGE_URL}/diagnose.png`,
        "paragraph": 'Mafhoom\'s tool uses AI to assess clients\' financial health, analyzing historical and behavioral data to offer targeted advice. Ideal for financial institutions and advisors, it deepens understanding of client financial habits and wellness.',
    },
    {
        "key" : 'cleanse-and-enrich',
        "title": 'Cleanse and Enrich',
        "heading": 'Refine Financial Data with AI Powered Data Cleansing & Enrichment',
        "image": `${BASE_IMAGE_URL}/cleanse-and-enrich.png`,
        "paragraph": 'Mafhoom revolutionizes business strategies with AI-powered data cleansing, enrichment, and categorization. Transform raw banking data into cleansed, defined and categorized data and actionable insights, improving customer understanding and enabling precise, swift decision-making. Boost customer satisfaction and engagement.',
    },
    {
        "key" : 'engage',
        "title": 'Engage',
        "heading": 'Enhance Engagement with Mafhoom\'s AI Personal Finance Tools',
        "image": `${BASE_IMAGE_URL}/engage.png`,
        "paragraph": 'AI-powered personal finance tools including budgeting assistants, spending trackers, and recommendations to engage with customers and boost financial wellness. Ensure deep engagement and personalized experiences, fostering durable customer relationships and improved financial well-being.',
    },
    {
        "key" : 'inform',
        "title": 'Inform',
        "heading": 'Tailored Financial Literacy Integrations',
        "image": `${BASE_IMAGE_URL}/inform.png`,
        "paragraph": 'Enhance your fintech and mobile banking solutions with our financial literacy product integration, providing a seamless user experience directly within your app. Empower your users with comprehensive financial wellness through embedded education and rewards, fostering responsible financial behavior.',
    },
];
const FEATURES = [
    {
        feature_id: 1,
        feature_title: 'Financial Health',
        feature_slug : 'Financial-Health',
        feature_description: `Have you ever wondered if your financial habits are helping you achieve your goals? 
        Discover the power of financial well-being like never before...`,
        feature_icon: `${process.env.PUBLIC_URL}/images/financial_health.png`,
        feature_text: 'feature text feature text feature text feature text feature text feature text feature text feature text feature text feature text feature text feature text',
        popup_feature_desc: 'Discover the power of financial well-being like never before.',
        popup_feature_icon: `${process.env.PUBLIC_URL}/images/financial_health.svg`,
        feature_details:{
            image: `${process.env.PUBLIC_URL}/images/financial_health_details.png`,
            points:[
                `With our Financial Health-Check Tool, you can gain insights into your overall financial health by determining your personalized financial health score, allowing you to pinpoint areas that need attention and identify practical ways to improve your financial standing.`,
                `Receive tailored recommendations based on your current financial status and goals, guiding you toward smarter financial decisions.`,
                `You can also monitor changes in your financial health over time to track progress and adapt strategies accordingly, ensuring that you stay on the path to financial success.`,
            ],
            description: `Have you ever wondered if your financial habits are helping you achieve your goals? 

Are you saving enough, or is debt becoming a burden?
            
Just like getting a regular health check-up, keeping tabs on your financial well-being is essential. But how can you do that effectively?`,

        }
    },
    {
        feature_id: 2,
        feature_title: 'Recurring',
        feature_slug : 'Recurring',
        feature_description: `Are you tired of managing multiple bills and subscriptions? 
        Simplify your financial life with Mafhoom's subscription tracking tool...`,
        feature_icon: `${process.env.PUBLIC_URL}/images/recurring.png`,
        feature_text: 'feature text feature text feature text feature text feature text feature text feature text feature text feature text feature text feature text feature text',
        popup_feature_desc: 'Keep tabs on your due payments, stay on track and stress-free.',
        popup_feature_icon: `${process.env.PUBLIC_URL}/images/recurring.svg`,
        feature_details:{
            image: `${process.env.PUBLIC_URL}/images/recurring_details.png`,
            points:[
                `This tool allows you to get a clear overview of all your upcoming expenses, from monthly bills to subscription renewals, which are conveniently displayed in one place.`,
                `You can easily review important details such as due dates, payment amounts, and preferred payment methods, empowering you to stay organized and in control.`,
                `Furthermore, you have the option to track recurring payments or set reminders, ensuring that your payments are always made on time and minimizing the risk of late fees and missed deadlines.`,
            ],
            description: `Are you tired of managing multiple bills and subscriptions? 

Simplify your financial life with Mafhoom's subscription tracking tool and say goodbye to manual bill management.`,

        }

    },
    {
        feature_id: 3,
        feature_title: 'Track Spending',
        feature_slug : 'Track-Spending',
        feature_description: 'Effortlessly categorize your expenses into predefined categories like bills and utilities, entertainment, and transport, or create custom categories tailored to your needs.',
        feature_icon: `${process.env.PUBLIC_URL}/images/track_spending.png`,
        feature_text: 'feature text feature text feature text feature text feature text feature text feature text feature text feature text feature text feature text feature text',
        popup_feature_desc: 'Gain clarity and control over your finances with intuitive spending tracker.',
        popup_feature_icon: `${process.env.PUBLIC_URL}/images/track_spending.svg`,
        feature_details:{
            image: `${process.env.PUBLIC_URL}/images/track_spending_details.png`,
            points:[
                `By organizing your spending, you'll gain valuable insights into your financial habits and identify areas where you can optimize your budget.`,
                `You can visualize your spending patterns using user-friendly charts and graphs, which will provide you with valuable insights into where your money goes. Whether grocery shopping, entertainment, or paying bills, keeping track of your expenses has never been more straightforward.`,
                `Take charge of your finances and make informed decisions with Mafhoom’s comprehensive spending tracker tool`,
            ],
            description: `Effortlessly categorize your expenses into predefined categories like bills and utilities, entertainment, and transport, or create custom categories tailored to your needs.`,

        }
    },
    {
        feature_id: 4,
        feature_title: 'Budget',
        feature_slug : 'Budget',
        feature_description: `Ever wondered where all your money goes each month?

With Mafhoom’s budgeting tool, you can finally get the answers you need to take control of your finances.`,
        feature_icon: `${process.env.PUBLIC_URL}/images/budget.png`,
        feature_text: 'feature text feature text feature text feature text feature text feature text feature text feature text feature text feature text feature text feature text',
        popup_feature_desc: 'Create customizable budgets that adapt to your needs and lifestyle.',
        popup_feature_icon: `${process.env.PUBLIC_URL}/images/budget.svg`,
        feature_details:{
            image: `${process.env.PUBLIC_URL}/images/budget_details.png`,
            points:[
                `You can customize your budget with categories like groceries, entertainment, and utilities, to fit your unique lifestyle.`,
                `Track your progress effortlessly and make adjustments whenever needed to stay within your financial goals. Our platform offers personalized recommendations to help you make the most of your money.`,
                `Say goodbye to financial stress and hello to financial freedom with Mafhoom's intuitive budgeting tool. Whether you aim to get out of debt or save for a dream vacation, we've got you covered every step of the way.`,
            ],
            description: `Ever wondered where all your money goes each month?

With Mafhoom’s budgeting tool, you can finally get the answers you need to take control of your finances.`,
        }
    },
    {
        feature_id: 5,
        feature_title: 'Education',
        feature_slug : 'Education',
        feature_description: 'Have you ever wanted to have the invaluable skills to navigate the financial landscape with confidence? Imagine being able to understand the complexities of the financial world...',
        feature_icon: `${process.env.PUBLIC_URL}/images/education.png`,
        feature_text: 'feature text feature text feature text feature text feature text feature text feature text feature text feature text feature text feature text feature text',
        popup_feature_desc: 'Enhance your financial literacy by diving into curated educational content.',
        popup_feature_icon: `${process.env.PUBLIC_URL}/images/education.svg`,
        feature_details:{
            image: `${process.env.PUBLIC_URL}/images/education_details.png`,
            points:[
                `Engage with interactive modules covering vital topics including budgeting, investing, saving money, and debt management, all at your preferred pace.`,
                `Mafhoom app’s user-friendly interface makes it effortless for you to reinforce your understanding by taking quizzes and assessments tailored to your learning journey,`,
                `As you progress through the modules, you can unlock rewards such as coins and XP points, which further enhance your knowledge and motivation to continue learning.`,
                `The Mafhoom app is designed to equip you with the tools and knowledge you need to make informed decisions that will positively impact your financial future.`,
            ],
            description: `Have you ever wanted to have the invaluable skills to navigate the financial landscape with confidence? 

Imagine being able to understand the complexities of the financial world and make informed decisions about your money. It's a powerful feeling, and we are here to help you achieve it. 
            
Explore our <strong> comprehensive education feature </strong> designed to elevate your financial literacy effortlessly.`,

        }
    },
    {
        feature_id: 6,
        feature_title: 'In-depth Insights',
        feature_slug : 'In-depth-Insights',
        feature_description: 'With in-depth insights, you can effectively monitor your transaction history and identify trends, anomalies, and potential areas for improvement...',
        feature_icon: `${process.env.PUBLIC_URL}/images/in-depth-insights.png`,
        feature_text: 'feature text feature text feature text feature text feature text feature text feature text feature text feature text feature text feature text feature text',
        popup_feature_desc: 'We help you decode the narrative, uncover insights, and make informed financial decisions.',
        popup_feature_icon: `${process.env.PUBLIC_URL}/images/in-depth-insights.svg`,
        feature_details:{
            image: `${process.env.PUBLIC_URL}/images/in-depth-insights-details.png`,
            points:[
                `You will gain valuable insights into your spending habits, including recurring expenses, impulse purchases, and seasonal trends. This will help you make informed decisions about your finances.`,
                `Additionally, data-driven recommendations tailored to your financial situation and goals will enable you to optimize your financial decisions and work toward achieving long-term financial stability and success`,
                `Setting your pay date allows you to monitor major expenses such as rent, loan payments, and investments strategically around your income cycles, ensuring better financial management.`,
            ],
            description: `With in-depth insights, you can effectively monitor your transaction history and identify trends, anomalies, and potential areas for improvement.`,

        }
    },
];
// window.addEventListener('resize', updateBaseUrl); // Update the base image URL on window resize

CONFIG['BASE_IMAGE_URL'] = BASE_IMAGE_URL;
CONFIG['isMobile'] = isMobile;
CONFIG['ONE_SIGNAL_APP_ID'] = "f7c9698a-1a92-4287-b56b-10e63a8fb466";

CONFIG['PRODUCTS'] = PRODUCTS;
CONFIG['FEATURES'] = FEATURES;

export default CONFIG;
