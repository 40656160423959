import React, { useState } from 'react';
import { Col, Row, Tab, Tabs } from 'react-bootstrap';
import CONFIG from '../../config';
import '../../styles/home.css';
import axios from 'axios';

const HomePage = () => {
  const products = CONFIG['PRODUCTS'];
  const mafhoomSolutionImage = `${CONFIG['BASE_IMAGE_URL']}/mafhoom-solution.png`;
  const financialDataEnrichment = `${CONFIG['BASE_IMAGE_URL']}/financial-data-enrichment.png`;
  const onlineBanking = `${CONFIG['BASE_IMAGE_URL']}/online-banking.png`;

  const mapImage = `${process.env.PUBLIC_URL}/images/map.png`;
  const locationImage = `${process.env.PUBLIC_URL}/images/location.png`;
  const calenderImage = `${process.env.PUBLIC_URL}/images/calender.png`;
  const timeImage = `${process.env.PUBLIC_URL}/images/time.png`;
  const emailImage = `${process.env.PUBLIC_URL}/images/email.png`;



  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [mobile, setMobile] = useState('');
  const [message, setMessage] = useState('');
  const [subject, setSubject] = useState('');

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };
  const handleNameChange = (event) => {
    setName(event.target.value);
  };
  const handleMobileChange = (event) => {
    setMobile(event.target.value);
  };
  const handleMessageChange = (event) => {
    setMessage(event.target.value);
  };
  const handleSubjectChange = (event) => {
    setSubject(event.target.value);
  };


  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const url = `https://dev.mafhoom.app/api/v1/web/contact_us?name=${encodeURIComponent(name)}&email=${encodeURIComponent(email)}&phone=${encodeURIComponent(mobile)}&subject=${encodeURIComponent(subject)}&description=${encodeURIComponent(message)}`;
      const response = await axios.post(url);
      if (response.status >= 200 && response.status < 300) {
        // Request was successful
        console.log('Form submitted successfully');
        // Reset form state
        setEmail('');
        setName('');
        setMobile('');
        setMessage('');
        setSubject('');
      } else {
        console.error('Form submission failed with status:', response.status);
      }
    } catch (error) {
      setEmail('');
      setName('');
      setMobile('');
      setMessage('');
      setSubject('');
      console.error('Error submitting form:', error);
    }
  };




  const [key, setKey] = useState(0); // State to manage active tab

  return (
    <div>
      <Row className='m-auto'>
        <h3 className='section-title baloo-font-normal'> Mafhoom Business Products </h3>
        <Tabs
          className="mafhoom-products justify-content-between"
          activeKey={key}
          onSelect={(k) => setKey(k)}
        >
          {products.map((product, index) => (
            <Tab
              key={index}
              className='montserrat-font-normal'
              eventKey={index}
              title={product.title}
            >
              <div className='tab-content row'>
                <Col md={6} className='my-auto'>
                  <h4 className='baloo-font-normal tab-heading'> {product.heading} </h4>
                  <p className='Montserrat-font-normal tab-paragraph'>{product.paragraph} </p>
                </Col>
                <Col md={6} className='m-auto'>
                  <img className='tab-image' src={product.image} alt="productImage" />
                </Col>
              </div>
            </Tab>
          ))}
        </Tabs>
      </Row>
      <Row className='mx-0 w-100'>
        <h3 className='section-title2 baloo-font-normal'> Mafhoom Solutions </h3>
        <div className='tab-content row home-section-2'>
          <div className='tab-content row  mx-auto '>

            <Col md={6} className='m-auto'>
              <a className='href-home'>Measuring Financial Health</a>
              <h4 className='baloo-font-normal tab-heading'> Financial Health and Wellness </h4>
              <p className='Montserrat-font-normal tab-paragraph paragraph'>Mafhoom's Financial Health and Wellness app offers a comprehensive tool for businesses to assess their clients' financial health. Leveraging AI to analyze clients' historical data and behavioral patterns, it provides insights into clients' financial habits, enabling businesses to offer targeted advice and services. This assessment tool is ideal for financial institutions, advisors, and any service requiring a deep understanding of client financial wellness.</p>
            </Col>
            <Col md={6} className='m-auto'>
              <img className='tab-image2' src={mafhoomSolutionImage} alt="mafhoomSolutionImage" />
            </Col>
          </div>
        </div>
      </Row>

      <Row className='m-auto'>
        <div className='tab-content row home-section-3 m-auto'>
          <Col md={6} className='m-auto'>
            <img className='tab-image3' src={financialDataEnrichment} alt="financialDataEnrichment" />
          </Col>
          <Col md={6} className='m-auto'>
            <a className='href-home'>Financial Data Cleansing & Enrichment</a>
            <h4 className='baloo-font-normal tab-heading'> Enhancing Decision-Making with Mafhoom's AI Data Enrichment </h4>
            <p className='Montserrat-font-normal tab-paragraph paragraph'>Leverage Mafhoom's AI data cleansing, enrichment, and advanced categorization to transform raw banking data into actionable insights. This technology enhances customer understanding, enabling businesses to make swift, precise decisions. Financial provider clients can benefit significantly as they receive personalized, data-driven services that improve their financial management and decision-making capabilities, enhancing overall customer satisfaction and engagement.</p>
          </Col>

        </div>
      </Row>

      <Row className='mx-0 w-100'>
        <div className='tab-content row home-section-22 home-section-3  '>
          <div className='tab-content row  mx-auto '>

            <Col md={6} className='m-auto'>
              <a className='href-home'>Open Banking Development & API Integration</a>
              <h4 className='baloo-font-normal tab-heading'>Optimizing Financial Services through Open Banking Integration</h4>
              <p className='Montserrat-font-normal tab-paragraph paragraph'>Mafhoom enhances business capabilities by integrating open banking solutions for advanced data and payments management. By accessing financial APIs, Mafhoom offers a strategic approach to harness customer financial data, enabling effective decision-making and service optimization. This service ensures efficient and secure processing of both data and transactions, empowering businesses to deliver superior financial services tailored to customer needs.</p>
            </Col>
            <Col md={6} className='m-auto'>
              <img className='tab-image2' src={onlineBanking} alt="financialDataEnrichment" />
            </Col>

          </div>
        </div>

      </Row>


      <Row className='m-auto'>
        <h3 className='section-title baloo-font-normal'> Why Mafhoom? </h3>
        <p className='href-home m-auto w-auto'> Elevate Your Fintech Experience with Mafhoom </p>
        <div className='tab-content row home-section-5 m-auto'>
          <Col md={4} className='m-auto d-flex'>
            <h4 className='baloo-font-normal tab-heading'> 01 </h4>
            <p className='Montserrat-font-normal tab-paragraph paragraph'>
              <strong> Bespoke Solutions Tailored Just for You: </strong>
              Dive into the world of custom-built open banking services designed to align perfectly with your business objectives.
            </p>
          </Col>

          <Col md={4} className='m-auto d-flex'>
            <h4 className='baloo-font-normal tab-heading'> 02 </h4>
            <p className='Montserrat-font-normal tab-paragraph paragraph'>
              <strong> Uncompromised Security for Your Data: </strong>
              Trust in our commitment to safeguard your and your customers' financial information with top-tier security measures.             </p>
          </Col>


          <Col md={4} className='m-auto d-flex'>
            <h4 className='baloo-font-normal tab-heading'> 03 </h4>
            <p className='Montserrat-font-normal tab-paragraph paragraph'>
              <strong> Revolutionizing User Experiences: </strong>
              Step into the future with enhanced financial services that are not just efficient but also seamlessly integrated and user-centric.             </p>
          </Col>

        </div>
      </Row>

      <div className='row m-auto section-map' id="contactUs">
        <h3 className='section-title baloo-font-normal' > Let's discuss further – Talk to us! </h3>
        <div className='map '>
          <form onSubmit={handleSubmit}>
            <Row className='m-auto'>
              <Col md={6} sm={6} className='map-form'>
                <Row>
                  <Col md={4}>
                    <label>Your Name</label>
                    <input
                      type="text"
                      id="name"
                      value={name}
                      onChange={handleNameChange}
                      required
                    />
                  </Col>
                  <Col md={4}>
                    <label>Your Email</label>
                    <input
                      type="email"
                      id="email"
                      value={email}
                      onChange={handleEmailChange}
                      required
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md={4}>
                    <label>Subject</label>
                    <input
                      type="subject"
                      id="subject"
                      value={subject}
                      onChange={handleSubjectChange}
                      required
                    />
                  </Col>
                  <Col md={4}>
                    <label>Your Phone Number</label>
                    <input
                      type="mobile"
                      id="mobile"
                      value={mobile}
                      onChange={handleMobileChange}
                      required
                    />
                  </Col>
                </Row>
                <Row>
                  <label>Message</label>
                  <textarea id="message"
                    value={message}
                    onChange={handleMessageChange}
                    required></textarea>
                </Row>
                <Row><button className='form-btn'>Submit</button></Row>
              </Col>
              <Col md={6} sm={6} className='map-img'>
                <img src={mapImage} alt="map"></img>
              </Col>
            </Row>
          </form>
        </div>
        <div className='mafhoom-info'>
          <div>
            <img className='icon-map' alt="locationImage" src={locationImage} />
            <span> Mafhoom Technologies Ltd. DIFC Innovation Hub, Level 1, Zone D.Dubai – UAE</span>
          </div>
          <div>
            <img className='icon-map' alt="emailImage" src={emailImage} />
            <span> hello@mafhoom.app </span>
          </div>
          <div>
            <img className='icon-map' alt="calenderImage" src={calenderImage} />
            <span> Sunday - Thursday</span>
          </div>
          <div>
            <img className='icon-map' alt="" src={timeImage} />
            <span> 8:00 - 17:00 (GMT+4)</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomePage;
